import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { EconomicGroup, EconomicGroupsResponse } from '../interfaces/economicGroups.interfaces';
import { setEconomicGroupList } from './economicGroupSlice';

export const economicGroupsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['EconomicGroups', 'EconomicGroupsAvailables'],
});

export const economicGroupsApi = economicGroupsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all economic groups
     */
    getEconomicGroups: builder.query<
      EconomicGroupsResponse,
      {
        profileId: number;
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        type_sort?: string;
      }
    >({
      queryFn: async ({ profileId, page, search, perPage, sort, type_sort }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<EconomicGroupsResponse>('/economic-group', {
            params: {
              user_profile_id: profileId,
              page,
              size: perPage,
              type_sort: type_sort || 'desc',
              ...(sort && { sort }),
              search,
            },
          });

          dispatch(setEconomicGroupList(data.data));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'EconomicGroups', id } as const)),
              { type: 'EconomicGroups', id: 'LIST' },
            ]
          : [{ type: 'EconomicGroups', id: 'LIST' }],
    }),

    /**
     * @GET all economic groups Avalibles
     */
    getAvaliblesEconomicGroups: builder.query<EconomicGroup[], { profileId: Number }>({
      queryFn: async ({ profileId }) => {
        try {
          const { data } = await mainApi.get<EconomicGroup[]>('/economic-group', {
            params: {
              user_profile_id: profileId,
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'EconomicGroupsAvailables', id: 'COMBO_ECGRP_LIST' }],
    }),

    /**
     * @GET EconomicGroup by id
     */
    getEconomicGroup: builder.query<EconomicGroup, { profileId?: number; economicGroupId: number }>(
      {
        queryFn: async ({ profileId, economicGroupId }, { dispatch, getState }) => {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          try {
            const { data } = await mainApi.get<EconomicGroup>(
              `/economic-group/${economicGroupId}`,
              {
                params: {
                  user_profile_id,
                },
              }
            );

            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        providesTags: (result, error, { profileId }) => [{ type: 'EconomicGroups', profileId }],
      }
    ),

    /**
     * @POST create economic group
     */
    addEconomicGroup: builder.mutation<
      EconomicGroup,
      { profileId: number; economicGroupPayload: Partial<EconomicGroup> }
    >({
      queryFn: async ({ profileId, economicGroupPayload }) => {
        try {
          const { data } = await mainApi.post<EconomicGroup>('/economic-group', {
            user_profile_id: profileId,
            ...economicGroupPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'EconomicGroups', id: 'LIST' }],
    }),

    /**
     * @PUT update economic group
     */
    updateEconomicGroup: builder.mutation<
      EconomicGroup,
      { profileId: number; economicGroupId: number; economicGroupPayload: Partial<EconomicGroup> }
    >({
      queryFn: async ({ profileId, economicGroupId, economicGroupPayload }) => {
        try {
          const { data } = await mainApi.put<EconomicGroup>(`/economic-group/${economicGroupId}`, {
            user_profile_id: profileId,
            ...economicGroupPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'EconomicGroups', id: 'LIST' }],
    }),

    /**
     * @DELETE remove economic group
     */
    deleteEconomicGroup: builder.mutation<
      EconomicGroup,
      { profileId: number; economicGroupId: number }
    >({
      queryFn: async ({ profileId, economicGroupId }) => {
        try {
          const { data } = await mainApi.delete(`/economic-group/${economicGroupId}`, {
            data: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'EconomicGroups', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetEconomicGroupsQuery,
  useAddEconomicGroupMutation,
  useDeleteEconomicGroupMutation,
  useGetEconomicGroupQuery,
  useUpdateEconomicGroupMutation,
  useGetAvaliblesEconomicGroupsQuery,
  useLazyGetEconomicGroupQuery,
} = economicGroupsApi;
