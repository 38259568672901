import { Grid, Stack, Typography, useTheme, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { IconStar } from '@tabler/icons';
import Avatar from '../../../shared/components/extended/Avatar';
import MailTwoToneIcon from '@material-ui/icons/MailTwoTone';
import { EnabledSubject } from '../interfaces/enabledSubjects.interfaces';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  boxCard: {
    boxShadow: '0px 4px 20px 15px rgba(234, 240, 246, 0.5);',
    borderRadius: 12,
    padding: '20px',
  },
  satckContainer: {
    backgroundColor: theme.palette.grey[50],
    padding: '10px',
    borderRadius: '10px',
  },
  stackTitle: {
    color: theme.grey500,
  },
  stackSubtitle: {
    fontWeight: 600,
    color: theme.palette.grey[800],
  },
  title: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  divider: { borderColor: theme.palette.grey[300] },
}));

interface Props {
  enable?: boolean;
  enableSubject: EnabledSubject;
}

const MatterEnableCard = ({ enable, enableSubject }: Props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const theme = useTheme();

  const getBorder = (): string => {
    return enable ? `2px solid ${theme.palette.primary.main}` : `0`;
  };

  return (
    <Grid container item xs={12} sm={6} md={4} xl={3} key={enableSubject.id}>
      <Grid container item className={classes.boxCard} sx={{ outline: getBorder() }}>
        <Grid container item xs={12} sx={{ mb: 2 }}>
          <Stack direction="row" justifyContent="center" sx={{ width: '100%' }}>
            <Typography variant="h3" sx={{ color: 'primary.main' }}>
              {enableSubject.matter.mat_name}
            </Typography>
          </Stack>
        </Grid>

        <Grid container item xs={12} sx={{ mb: 2 }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%', mb: 1 }}
          >
            <Typography className={classes.satckContainer}>
              <strong>{enableSubject.available_quotas}</strong>{' '}
              {t('students.subjects.available.spots')}
            </Typography>
          </Stack>
          {/* <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%' }}
            spacing={1}
          >
            <IconStar className={classes.title} />
            <Typography className={classes.title}>Te recomendamos esta materia</Typography>
          </Stack> */}
        </Grid>

        <Grid container item xs={12} sx={{ mb: 2 }}>
          <Stack justifyContent="space-between" sx={{ width: '100%' }} spacing={1}>
            <Stack direction="row" spacing={4}>
              <Typography>{t('students.subjects.available.schedule')}</Typography>
            </Stack>

            <Stack direction="row" spacing={4}>
              <Typography className={classes.stackSubtitle}>
                {enableSubject.hourhand.hour_description}
              </Typography>
            </Stack>
          </Stack>
        </Grid>

        <Grid container item xs={12} sx={{ mb: 2 }}>
          <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
            <Stack direction="row" spacing={2}>
              <Typography className={classes.stackTitle}>
                {t('students.subjects.available.classroom')}
              </Typography>
              <Typography className={classes.stackSubtitle}>
                {enableSubject.classroom.cl_name}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Typography className={classes.stackTitle}>
                {t('students.subjects.available.parallel')}
              </Typography>
              <Typography className={classes.stackSubtitle}>
                {enableSubject.parallel.par_name}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid container item xs={12} sx={{ mb: 2 }}>
          <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
            <Stack direction="row" spacing={2}>
              <Typography className={classes.stackTitle}>
                {t('students.subjects.available.modality')}
              </Typography>
              <Typography className={classes.stackSubtitle}>
                {enableSubject.modality.cat_name}
              </Typography>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider classes={{ root: classes.divider }} />
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid container item justifyContent="center" alignItems="center" xs={3}>
            <Avatar sx={{ width: '48px', height: '48px' }} />
          </Grid>
          <Grid container item xs={9}>
            <Stack direction="column" justifyContent="space-between" sx={{ width: '100%' }}>
              <Stack spacing={1}>
                <Typography className={classes.stackTitle}>
                  {t('students.subjects.available.teacher')}
                </Typography>
                <Typography className={classes.stackSubtitle}>
                  {enableSubject?.collaborator
                    ? `${
                        enableSubject.collaborator.user.person.pers_firstname +
                        ' ' +
                        enableSubject.collaborator.user.person.pers_first_lastname
                      } `
                    : t('students.subjects.available.no_teacher')}
                </Typography>
                <Stack direction="row" spacing={1}>
                  <MailTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                  <Typography
                    sx={{
                      wordBreak: 'break-all',
                    }}
                  >
                    {enableSubject?.collaborator?.coll_email ||
                      t('students.subjects.available.no_email')}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
          <Divider classes={{ root: classes.divider }} />
        </Grid>

        {/* <Grid container item xs={12} gap={2}>
          <Button startIcon={<IconUserCheck />} variant="contained" fullWidth>
            Registrarme
          </Button>
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default MatterEnableCard;
