import { Icon, Theme } from '@material-ui/core';
import { FC } from 'react';
import telework from '../../../../assets/images/illustrations/telework.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
  iconRoot: {
    textAlign: 'center',
  },
}));

interface Props {
  size?: number | undefined;
}

const IconTelework: FC<Props> = (props) => {
  const classes = useStyles();
  const { size } = props;
  return (
    <Icon
      classes={{ root: classes.iconRoot }}
      sx={{ height: size || '100px', width: size || '100px' }}
    >
      <img className={classes.imageIcon} alt="telework icon await" src={telework} />
    </Icon>
  );
};

export default IconTelework;
