import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import EvaluationComments from '../components/comments/EvaluationComments';
import EvaluationConfirmDialog from '../components/detail/EvaluationConfirmDialog';
import EvaluationList from '../components/EvaluationList';

const EvaluationListScreen = () => {
  const { t } = useTranslation();
  const { confirmStartDialog, evaluationComments } = useAppSelector(
    (state) => state.virtualClassroomStudent.evaluation
  );

  return (
    <React.Fragment>
      <Grid
        component={MainCard}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">{t('studentVirtualClassroom.menu.tests')}</Typography>
        </Grid>
        <EvaluationList />
      </Grid>
      {confirmStartDialog && <EvaluationConfirmDialog />}
      {evaluationComments && <EvaluationComments />}
    </React.Fragment>
  );
};

export default EvaluationListScreen;
