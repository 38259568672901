import { 
    //useHistory
 } from 'react-router-dom';
import {
    Autocomplete,
    //Checkbox,
    Button,
    Grid,
    TextField,
    /* TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    CircularProgress,
    FormHelperText,
    Collapse,
    Typography,
    Box,
    useTheme, */
    // FormControlLabel,
} from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import InputLabel from '../../../shared/components/forms/InputLabel';

const ReportsForm = () => {
    //const history = useHistory();

    return (
        <Grid container direction="column" gap={2} xs={12} md={6} lg={6}>
            {/* <Grid item>
                {isError && showAlerts && (
                    <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
                )}

                {isSuccess && showAlerts && (
                    <SuccessAlert
                        message="La sede ha sido creado correctamente."
                        handleDismiss={() => setShowAlerts(false)}
                    />
                )}
            </Grid> */}

            <Grid item container spacing={2} alignItems="center">

                <Grid item xs={12}>
                    <InputLabel>Fecha Inicial</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                        <DatePicker
                            value=""
                            onChange={(newValue) => {
                                /* const value = newValue as any;
                                setFieldValue('pers_date_birth', value);
          
                                if (value ? value!.getTime() > 0 : false) {
                                  setInputsData((prevState) => {
                                    return {
                                      ...prevState,
                                      pers_date_birth: format(value, 'yyyy-MM-dd'),
                                    };
                                  });
                                } */
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={false}
                                    //error={Boolean(errors.pers_date_birth && touched.pers_date_birth)}
                                    helperText=""
                                    placeholder="Fecha de creación"
                                    fullWidth
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>Fecha Final</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                        <DatePicker
                            value=""
                            onChange={(newValue) => {
                                /* const value = newValue as any;
                                setFieldValue('pers_date_birth', value);
          
                                if (value ? value!.getTime() > 0 : false) {
                                  setInputsData((prevState) => {
                                    return {
                                      ...prevState,
                                      pers_date_birth: format(value, 'yyyy-MM-dd'),
                                    };
                                  });
                                } */
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={false}
                                    //error={Boolean(errors.pers_date_birth && touched.pers_date_birth)}
                                    helperText=""
                                    placeholder="Fecha de creación"
                                    fullWidth
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>


                <Grid item xs={12}>
                    <InputLabel>Clasificación</InputLabel>
                    <Autocomplete
                        options={[]}
                        placeholder="Seleccione Clasificación..."
                        //getOptionLabel={option => option.name}
                        renderInput={params => (
                            <TextField {...params}
                                placeholder="Seleccione Clasificación..."

                            //error={Boolean(errors.statusId && touched.statusId)} 
                            />
                        )}
                        //defaultValue={}
                        onChange={(e, value) => { }
                            //setFieldValue('statusId', value?.id || '')
                        }
                        fullWidth
                        clearIcon={null}
                    />
                </Grid>

                <Grid item xs={12}>
                    <InputLabel>Tipo de Reporte</InputLabel>
                    <Autocomplete
                        options={[]}
                        placeholder="Seleccione Tipo de Reporte..."
                        //getOptionLabel={option => option.name}
                        renderInput={params => (
                            <TextField {...params}
                                placeholder="Seleccione Tipo de Reporte..."

                            //error={Boolean(errors.statusId && touched.statusId)} 
                            />
                        )}
                        //defaultValue={}
                        onChange={(e, value) => { }
                            //setFieldValue('statusId', value?.id || '')
                        }
                        fullWidth
                        clearIcon={null}
                    />
                </Grid>



            </Grid>

            <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                sx={{ mt: 1 }}
                xs={12}
                md={6}
            >
                <Grid item>
                    {/* <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={() => history.goBack()}
                    >
                        Regresar
                    </Button> */}
                </Grid>
                <Grid item>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        color="primary"
                    //disabled={isLoading}
                    //endIcon={isLoading && <CircularProgress size={20} />}
                    >
                        Generar Reporte
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ReportsForm;