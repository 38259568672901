const days = [
  { d: 'Monday', val: 'Lunes' },
  { d: 'Tuesday', val: 'Martes' },
  { d: 'Wednesday', val: 'Miércoles' },
  { d: 'Thursday', val: 'Jueves' },
  { d: 'Friday', val: 'Viernes' },
  { d: 'Saturday', val: 'Sabado' },
  { d: 'Sunday', val: 'Domingo' },
];
/*eslint-disable*/

export const getFormatHourhand = (hourh: any) => {
  let hourhand = '';

  for (const [key, value] of Object.entries(hourh)) {
    days.forEach((d) => {
      if (key === `hour_${d.d.toLowerCase()}` && value === '1') {
        const start_ = String(hourh[`hour_start_time_${d.d.toLowerCase()}`]);
        const end_ = String(hourh[`hour_end_time_${d.d.toLowerCase()}`]);
        hourhand = `${d.val} - ${start_.substring(0, start_.length - 3)} a ${end_.substring(
          0,
          end_.length - 3
        )} `;
      }
    });
  }

  return hourhand;
};
