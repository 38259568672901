import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/core/Autocomplete';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import InputLabel from '../../../../../../shared/components/forms/InputLabel';
import {
  ProjectStepsPayload,
  ProjectDocuments,
  Project,
} from '../../../../interfaces/projectResearch.interfaces';
import { MoreDetailsProjectSchema } from '../../../../constants/wizard.validationSchemas';
import {
  useGetComplianceParametersAllAvailablesQuery,
  useDeleteDocumentProjectMutation,
} from '../../../../slices/InvestigationLinesApiSlice';
import statusProjectAvailables from '../../../../constants/lists/statusProject';
// import { statusAvailables } from '../../../../../../shared/constants/resourceStatus';
import DragArea from '../../../../../../shared/components/files/DragArea';
import { useUploadFilesMutation } from '../../../../../../shared/slices/uploadFilesApi';
import TextFieldFetching from '../../../../../../shared/components/TextFieldFetching';
import { useGetDocumentsByProjectsResearchQuery } from '../../../../slices/InvestigationLinesApiSlice';
import useGetUrlDownload from '../../../../../../../hooks/useGetUrlDownload';
import { Reseteable } from '.';
import { useAppSelector } from '../../../../../../../hooks/useRedux';

interface Props extends Reseteable {
  setData: React.Dispatch<React.SetStateAction<ProjectStepsPayload>>;
  getData: ProjectStepsPayload;
  setNext: React.Dispatch<React.SetStateAction<number>>;
  sendSubmit: (dataPayload: ProjectStepsPayload) => void;
  isLoading: boolean;
  isEdit?: boolean;
  dataEdit?: Project;
}

const GeneralDetailsForm = ({
  sendSubmit,
  setNext,
  isLoading,
  getData,
  isEdit = false,
  setData,
  dataEdit,
  isReset,
  handleReset,
}: Props) => {
  const { t } = useTranslation();

  const { defaultConfiguration } = useAppSelector((state) => state.access);

  const [deleteProject, { isLoading: isLoadingDelete }] = useDeleteDocumentProjectMutation();

  const { data: complianceParametersAvailables, isLoading: isLoadingCompliance } =
    useGetComplianceParametersAllAvailablesQuery({});

  const { data: documentsAvailables, isLoading: isLoadingDocuments } =
    useGetDocumentsByProjectsResearchQuery({ projectId: dataEdit?.id || '' }, { skip: !isEdit });

  const handleReturn = () => {
    setNext((step) => step - 1);
  };

  const { urlDownload } = useGetUrlDownload();

  const [uploadFile, { isLoading: isLoadingFile }] = useUploadFilesMutation();

  const [file, setFile] = useState<File>(null!);

  const onChangeFile = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);

      setFieldValue('projectDocuments', []);
    }
    setFieldValue('typeFile', event?.target?.files?.[0]?.type || '');
  };

  const handleDeleteDocument = (id: string) => {
    deleteProject({ documentId: id });
  };

  // const [files, setFiles] = useState({} as ProjectDocuments);

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values, resetForm } =
    useFormik({
      initialValues: {
        activityDescription: isEdit ? dataEdit?.activity_description || '' : '',
        fieldDetailed: isEdit ? dataEdit?.field_detailed || '' : '',
        plannedBudget: isEdit ? Number(dataEdit?.planned_budget) || 0 : 0,
        executedBudget: isEdit ? Number(dataEdit?.executed_budget) || 0 : 0,
        statusProject: isEdit ? dataEdit?.status_project || 'DETENIDO' : 'DETENIDO',
        complianceParameterId: isEdit ? dataEdit?.compliance_parameter_id || '' : '',
        status: isEdit ? dataEdit?.status || 'active' : 'active',
        projectDocuments: [] as ProjectDocuments[],
        typeFile: 'application/pdf',
      },
      validationSchema: MoreDetailsProjectSchema,
      validate: ({ typeFile }) => {
        const errors: any = {};

        if (!!typeFile && typeFile !== 'application/pdf') {
          errors.typeFile = 'Tipo de archivo no permitido';
        }
        return errors;
      },
      onSubmit: async ({
        activityDescription,
        complianceParameterId,
        executedBudget,
        fieldDetailed,
        plannedBudget,
        projectDocuments,
        status,
        statusProject,
      }) => {
        if (!!file?.name) {
          uploadFile({
            files: file!,
          })
            .unwrap()
            .then(async (response) => {
              if (response) {
                await sendSubmit({
                  ...getData,
                  moreDetailsProject: {
                    activity_description: activityDescription,
                    compliance_parameter_id: complianceParameterId,
                    executed_budget: executedBudget,
                    field_detailed: fieldDetailed,
                    planned_budget: plannedBudget,
                    project_documents: [
                      ...(!!documentsAvailables?.length
                        ? documentsAvailables?.map(
                            ({ id, doc_url, type_document_id, doc_name_file }) => ({
                              id,
                              doc_url,
                              type_document_id: Number(type_document_id),
                              doc_name_file,
                            })
                          )
                        : []),
                      {
                        id: null,
                        doc_url: response,
                        type_document_id: Number(file.type),
                        doc_name_file: file.name,
                      },
                    ],
                    status,
                    status_project: statusProject,
                  },
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          sendSubmit({
            ...getData,
            moreDetailsProject: {
              activity_description: activityDescription,
              compliance_parameter_id: complianceParameterId,
              executed_budget: executedBudget,
              field_detailed: fieldDetailed,
              planned_budget: plannedBudget,
              project_documents: documentsAvailables?.map(
                ({ id, doc_url, type_document_id, doc_name_file }) => ({
                  id,
                  doc_url,
                  type_document_id: Number(type_document_id),
                  doc_name_file,
                })
              ) || [],

              status,
              status_project: statusProject.toString(),
            },
          });
        }
      },
    });

  useEffect(() => {
    if (isReset) {
      resetForm();
    }
  }, [isReset, resetForm]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column">
        <Grid item container xs={12} md={6} spacing={2}>
          {/* <Grid item xs={12}>
            <InputLabel> {t('project.MoreDetails.fieldDetail.title')} </InputLabel>
            <TextField
              {...getFieldProps('fieldDetailed')}
              error={Boolean(errors.fieldDetailed && touched.fieldDetailed)}
              placeholder={t('project.MoreDetails.fieldDetail.placeholder')}
              fullWidth
            />
            {errors.fieldDetailed && touched.fieldDetailed && (
              <FormHelperText error>{t(errors.fieldDetailed)}</FormHelperText>
            )}
          </Grid> */}

          <Grid item xs={12}>
            <InputLabel> {t('project.MoreDetails.activityDescription.title')} </InputLabel>
            <TextField
              {...getFieldProps('activityDescription')}
              error={Boolean(errors.activityDescription && touched.activityDescription)}
              placeholder={t('project.MoreDetails.activityDescription.placeholder')}
              fullWidth
            />
            {errors.activityDescription && touched.activityDescription && (
              <FormHelperText error>{t(errors.activityDescription)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel> {t('project.MoreDetails.plannedBudget.placeholder')} </InputLabel>
            <TextField
              {...getFieldProps('plannedBudget')}
              error={Boolean(errors.plannedBudget && touched.plannedBudget)}
              placeholder={t('project.MoreDetails.plannedBudget.placeholder')}
              fullWidth
              InputProps={{
                startAdornment: (
                  <Typography variant="body1">{defaultConfiguration?.currency_symbol}</Typography>
                ),
              }}
            />
            {errors.plannedBudget && touched.plannedBudget && (
              <FormHelperText error>{t(errors.plannedBudget)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel> {t('project.MoreDetails.executedBudget.title')} </InputLabel>
            <TextField
              {...getFieldProps('executedBudget')}
              error={Boolean(errors.executedBudget && touched.executedBudget)}
              placeholder={t('project.MoreDetails.executedBudget.placeholder')}
              fullWidth
              InputProps={{
                startAdornment: (
                  <Typography variant="body1">{defaultConfiguration?.currency_symbol}</Typography>
                ),
              }}
            />
            {errors.executedBudget && touched.executedBudget && (
              <FormHelperText error>{t(errors.executedBudget)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={12}>
            <InputLabel> {t('project.MoreDetails.complianceParameter.title')} </InputLabel>
            {isLoadingCompliance ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={complianceParametersAvailables || []}
                loading={isLoadingCompliance}
                value={
                  complianceParametersAvailables?.find(
                    ({ id }) => id === values.complianceParameterId
                  ) || null
                }
                loadingText={t('academic.subjects.add.edu_level.loading')}
                noOptionsText={t('project.MoreDetails.complianceParameter.no_option')}
                getOptionLabel={(option) => option.description}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.complianceParameterId && touched.complianceParameterId)}
                    placeholder={t('project.MoreDetails.complianceParameter.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('complianceParameterId', value?.id);
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.complianceParameterId && touched.complianceParameterId && (
              <FormHelperText error>{errors.complianceParameterId}</FormHelperText>
            )}
          </Grid>

          {isLoadingDocuments && (
            <Grid item p={2}>
              <Typography variant="body1" color="primary">
                ... Cargando documentos
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} mt={2}>
            <InputLabel>{t('lines_research.documents.title')}</InputLabel>
          </Grid>

          {documentsAvailables?.map(({ doc_name_file, doc_url, id }) => (
            <Grid item xs={12} mt={2}>
              <Paper elevation={2} sx={{ width: '100%', padding: '5px' }}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item p={2}>
                    <Link href={urlDownload + doc_url} target="_blank">
                      {doc_name_file}
                    </Link>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="test"
                      disabled={isLoadingDelete}
                      onClick={() => handleDeleteDocument(id)}
                    >
                      <HighlightOffIcon color="primary" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}

          <Grid item xs={12} mb={3}>
            <DragArea typesAccepted=".pdf" handleChange={onChangeFile} isLoading={isLoadingFile} />

            {errors.projectDocuments && touched.projectDocuments && (
              <FormHelperText error>{errors.projectDocuments}</FormHelperText>
            )}

            {errors.typeFile && touched.typeFile && (
              <FormHelperText error>{errors.typeFile}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel> {t('lines_research.statusProject.title')} </InputLabel>
            <Autocomplete
              options={
                // isEdit
                //   ? statusProjectAvailables.filter(
                //       ({ keyword }) => keyword === 'EN EJECUCION' || keyword === 'EN ESPERA'
                //     )
                //   :
                statusProjectAvailables
              }
              value={
                statusProjectAvailables.find(({ keyword }) => keyword === values.statusProject) ||
                null
              }
              loadingText={t('lines_research.statusProject.loading')}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.statusProject && touched.statusProject)}
                  placeholder={t('lines_research.statusAvailables.placeholder')}
                />
              )}
              onChange={(_, value) => {
                setFieldValue('statusProject', value?.keyword);
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.statusProject && touched.statusProject && (
              <FormHelperText error>{errors.statusProject}</FormHelperText>
            )}
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ mt: 6 }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={handleReturn}
              >
                {t('academic.subjects.add.back')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                {t('project.save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default GeneralDetailsForm;
