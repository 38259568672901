import { IconButton, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { IconX } from '@tabler/icons';
import { DebounceInput } from 'react-debounce-input';

interface SearchInputProps {
  search: string;
  setSearch: (search: string) => void;
  searchPlaceholder: string;
  setPage: (page: number) => void;
  size?: 'small' | 'medium' | undefined;
}
const SearchInput = ({
  search,
  setSearch,
  searchPlaceholder,
  setPage,
  size = 'small',
}: SearchInputProps) => (
  <DebounceInput
    autoFocus={Boolean(search)}
    minLength={2}
    debounceTimeout={300}
    onChange={({ target }: { target: { value: string } }) => {
      setPage(1);
      setSearch(target.value);
    }}
    value={search}
    /* eslint-disable */
    element={(props: JSX.IntrinsicAttributes & TextFieldProps) => (
      <TextField
        style={{
          width: '100%',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search fontSize="medium" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => {
                  setSearch('');
                }}
                {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
              >
                <IconX size={20} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        placeholder={searchPlaceholder}
        variant="outlined"
        size={size}
        {...props}
      />
    )}
  />
);

SearchInput.defaultProps = {
  size: 'small',
};

export default SearchInput;
