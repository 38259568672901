import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useDeleteStudentsProjectResearchDetailsMutation } from '../slices/followUpOfResearchProjectApiSlice';
import {
  setBinnacleSelected,
  setDeleteBinnancleDialog,
} from '../slices/followUpOfResearchProjectSlice';

const DeleteBinnacleDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteBinnancleDialog, binnacleSelected } = useAppSelector(
    (state) => state.followUpOfResearch
  );

  const [deleteBinnancle, { isLoading, isSuccess, isError, error }] =
    useDeleteStudentsProjectResearchDetailsMutation();

  const handleClose = () => {
    dispatch(setBinnacleSelected(null));
    dispatch(setDeleteBinnancleDialog(false));
  };

  const handleDelete = () => {
    deleteBinnancle({
      studentProjectResearchDetails: binnacleSelected?.id!,
    });
  };

  return (
    <DeleteAlert
      open={deleteBinnancleDialog}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteBinnacleDialog;
