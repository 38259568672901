import { Box, Grid, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { FormikStep } from '../../../hr/collaborators/components/CollaboratorsStepper';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import SubCard from '../../../shared/components/cards/SubCard';
import IconTelework from '../../../shared/components/illustrations/IconTelework';
import {
  BossSchema,
  EnterpriseSchema,
  fileSchema,
  PracticeSchema,
} from '../constants/validationSchemas';
import {
  useAddPracticeMutation,
  useUpdatePracticeMutation,
} from '../slices/academicRequestsApiSlice';
import BossStep from './ProfessionalPracticesWizard/BossStep';
import EnterpriseStep from './ProfessionalPracticesWizard/EnterpriseStep';
import FileStep from './ProfessionalPracticesWizard/FileStep';
import PracticeStep from './ProfessionalPracticesWizard/PracticeStep';
import { ProfessionalPracticesStepper } from './ProfessionalPracticesWizard/ProfessionalPracticesStepper';

import { useTranslation } from 'react-i18next';
import { useGetGeneralApplicationHomologationByIdQuery } from '../../../maintenance/general-applications/slices/GeneralApplicationsApiSlice';
import { useParams } from 'react-router-dom';

import {
  BossDetail,
  CompanieDetail,
  Practice,
} from '../../../maintenance/general-applications/interfaces/generalApplications.interfaces';
import { PracticeData } from '../interfaces/practiceRequest.interfaces';
import { format, parseISO } from 'date-fns';
import { convertTime12to24 } from '../helpers';
import { setEditDataApp } from '../../../maintenance/general-applications/slices/GeneralApplicationsSlice';

interface Props {
  isEdit?: boolean;
}

const ProfessionalPracticesForm = ({ isEdit = false }: Props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { currentProfile, currentRecordStudent } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);

  const [showAlerts, setShowAlerts] = useState(false);

  const { app: appId } = useParams<{ app: string }>();

  const [addPractice, { isError, error, isSuccess }] = useAddPracticeMutation();
  const [updatePractice] = useUpdatePracticeMutation();

  const hmFormat = (dateFormat: Date) => {
    console.log(dateFormat);

    let hours = dateFormat.getHours();
    let minutes = dateFormat.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? 0 + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };
  const getHourhand = (
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
    sunday: boolean,
    hourhand_start: Date,
    hourhand_end: Date
  ) => {
    return monday || tuesday || wednesday || thursday || friday || saturday || sunday
      ? (monday ? 'Lunes,' : '') +
          (tuesday ? 'Martes,' : '') +
          (wednesday ? 'Miércoles,' : '') +
          (thursday ? 'Jueves,' : '') +
          (friday ? 'Viernes,' : '') +
          (saturday ? 'Sábado,' : '') +
          (sunday ? 'Domingo,' : '') +
          hmFormat(hourhand_start) +
          '-' +
          hmFormat(hourhand_end)
      : '';
  };
  const dataForm1 = (values: any) => {
    return {
      companie_name: values.enterpriseName,
      companie_reason: values.reason,
      companie_ruc: values.ruc,
      companie_activity: values.activity,
      companie_address: values.address,
    };
  };
  const dataForm2 = (values: any) => {
    return {
      boss_name: values.bossName,
      boss_position: values.bossPosition,
      boss_email: values.bossEmail,
      boss_tlf: values.bossTlf,
    };
  };
  const dataForm3 = (values: any) => {
    return {
      practice_position: values.practicePosition,
      practice_start_date: values.practiceStartDate,
      practice_end_date: values.practiceEndDate,
      practice_hourhand: getHourhand(
        values.practiceHourMonday,
        values.practiceHourTuesday,
        values.practiceHourWednesday,
        values.practiceHourThursday,
        values.practiceHourFriday,
        values.practiceHourSaturday,
        values.practiceHourSunday,
        values.practiceHourStart,
        values.practiceHourEnd
      ),
    };
  };

  const { data: app } = useGetGeneralApplicationHomologationByIdQuery(
    {
      ApplicationHomId: Number(appId),
      profileId: currentProfile?.profileId!,
    },
    {
      skip: !currentProfile?.profileId || !appId || !isEdit,
    }
  );

  const details = app?.applicationDetails as Practice;

  const startDate = isEdit
    ? parseISO(
        `${format(
          parseISO(
            (details?.[0]?.value?.[2] as PracticeData)?.practice_end_date! as unknown as string
          ),
          'yyyy-MM-dd'
        )} ${convertTime12to24(
          (details?.[0]?.value?.[2] as PracticeData)?.practice_hourhand
            ?.split('-')?.[0]
            ?.split(',')
            ?.at(-1) as unknown as string
        )}`
      )
    : new Date();

  console.log('startDate', startDate);

  const endDate = isEdit
    ? parseISO(
        `${format(
          parseISO(
            (details?.[0]?.value?.[2] as PracticeData)?.practice_end_date! as unknown as string
          ),
          'yyyy-MM-dd'
        )} ${convertTime12to24(
          (details?.[0]?.value?.[2] as PracticeData)?.practice_hourhand?.split(
            '-'
          )[1] as unknown as string
        )}`
      )
    : new Date();

  return userInfo?.studentInfo?.typeStudent !== 'NIV' ? (
    <>
      <Grid sx={{ my: 3 }}>
        <Typography variant="h3">Crear solicitud</Typography>
      </Grid>
      <Grid container>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="La solicitud para las prácticas pre profesionales ha sido registrada correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
      </Grid>
      <ProfessionalPracticesStepper
        initialValues={{
          enterpriseName: isEdit ? (details?.[0]?.value?.[0] as CompanieDetail)?.companie_name : '',
          ruc: isEdit ? (details?.[0]?.value?.[0] as CompanieDetail)?.companie_ruc : '',
          address: isEdit ? (details?.[0]?.value?.[0] as CompanieDetail)?.companie_address : '',
          reason: isEdit ? (details?.[0]?.value?.[0] as CompanieDetail)?.companie_reason : '',
          activity: isEdit ? (details?.[0]?.value?.[0] as CompanieDetail)?.companie_activity : '',
          bossName: isEdit ? (details?.[0]?.value?.[1] as BossDetail)?.boss_name : '',
          bossPosition: isEdit ? (details?.[0]?.value?.[1] as BossDetail)?.boss_position : '',
          bossEmail: isEdit ? (details?.[0]?.value?.[1] as BossDetail)?.boss_email : '',
          bossTlf: isEdit ? (details?.[0]?.value?.[1] as BossDetail)?.boss_tlf : '',
          practicePosition: isEdit
            ? (details?.[0]?.value?.[2] as PracticeData)?.practice_position
            : '',
          practiceStartDate: isEdit
            ? parseISO(
                (details?.[0]?.value?.[2] as PracticeData)
                  ?.practice_start_date! as unknown as string
              )
            : new Date(),
          practiceEndDate: isEdit
            ? parseISO(
                (details?.[0]?.value?.[2] as PracticeData)?.practice_end_date! as unknown as string
              )
            : new Date(),
          practiceHourMonday: isEdit
            ? (details?.[0]?.value?.[2] as PracticeData)?.practice_hourhand?.includes('Lunes')
            : false,
          practiceHourStart: isEdit ? startDate : new Date(),
          practiceHourEnd: isEdit ? endDate : new Date(),
          practiceHourTuesday: isEdit
            ? (details?.[0]?.value?.[2] as PracticeData)?.practice_hourhand?.includes('Martes')
            : false,
          practiceHourWednesday: isEdit
            ? (details?.[0]?.value?.[2] as PracticeData)?.practice_hourhand?.includes('Miércoles')
            : false,
          practiceHourThursday: isEdit
            ? (details?.[0]?.value?.[2] as PracticeData)?.practice_hourhand?.includes('Jueves')
            : false,
          practiceHourFriday: isEdit
            ? (details?.[0]?.value?.[2] as PracticeData)?.practice_hourhand?.includes('Viernes')
            : false,
          practiceHourSaturday: isEdit
            ? (details?.[0]?.value?.[2] as PracticeData)?.practice_hourhand?.includes('Sábado')
            : false,
          practiceHourSunday: isEdit
            ? (details?.[0]?.value?.[2] as PracticeData)?.practice_hourhand?.includes('Domingo')
            : false,
          files: details?.[1]?.value || null,
        }}
        onSubmit={async (values) => {
          if (!isEdit) {
            await addPractice({
              profileId: currentProfile?.profileId!,
              userId: String(userInfo?.id!),
              practiceRequestPayload: {
                files: values.files,
                type_document: 2,
                app_description: '',
                student_record_id: currentRecordStudent?.id!,
                companie_data: [dataForm1(values), dataForm2(values), dataForm3(values)],
              },
            });
          } else {
            await updatePractice({
              profileId: currentProfile?.profileId!,
              appId: Number(appId),
              practiceRequestPayload: {
                companie_data: [dataForm1(values), dataForm2(values), dataForm3(values)],
              },
            });

            dispatch(setEditDataApp(false));
          }
          setShowAlerts(true);
        }}
      >
        <FormikStep
          label={t('students.applications.professional_practices.label_tab1')}
          validationSchema={EnterpriseSchema}
        >
          <Box paddingBottom={3} paddingTop={3}>
            <EnterpriseStep />
          </Box>
        </FormikStep>
        <FormikStep
          label={t('students.applications.professional_practices.label_tab2')}
          validationSchema={BossSchema}
        >
          <Box paddingBottom={3} paddingTop={3}>
            <BossStep />
          </Box>
        </FormikStep>
        <FormikStep
          label={t('students.applications.professional_practices.label_tab3')}
          validationSchema={PracticeSchema}
        >
          <Box paddingBottom={3} paddingTop={3}>
            <PracticeStep />
          </Box>
        </FormikStep>
        <FormikStep
          label={t('students.applications.professional_practices.label_tab4')}
          validationSchema={!isEdit && fileSchema}
        >
          <Box paddingBottom={3} paddingTop={3}>
            <FileStep />
          </Box>
        </FormikStep>
      </ProfessionalPracticesStepper>
    </>
  ) : (
    <Grid container item mt={3} justifyContent="center">
      <SubCard title="Aviso importante" darkTitle={true}>
        <Grid item>
          <ErrorAlert message={t('students.applications.error_message')} />
        </Grid>
        <Box>
          <Grid container justifyContent="center" alignContent="center">
            <Grid container justifyContent="center" item xs={8} md={6} spacing={2}>
              <Grid container item xs={12} justifyContent="center">
                <IconTelework size={300} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </SubCard>
    </Grid>
  );
};
export default ProfessionalPracticesForm;
