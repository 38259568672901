import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { resetAuthorToDelete, setDeleteModalOpen } from '../slices/authorsSlice';
import { useDeleteAuthorMutation } from '../slices/authorsApiSlice';
import { useTranslation } from 'react-i18next';

const DeleteAuthorDialog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { deleteModalOpen, authorToDelete } = useAppSelector((state) => state.authors);
  const [deleteAuthor, { isLoading, isSuccess, isError, error }] = useDeleteAuthorMutation();

  const handleDelete = () => {
    deleteAuthor({
      id: authorToDelete || NaN,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetAuthorToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={String(error)}
      deleteTitle={t('authors.delete.title')}
      deleteSuccess={t('authors.delete.success.message')}
    />
  );
};

export default DeleteAuthorDialog;
