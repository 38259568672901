import { Route, Switch } from 'react-router-dom';
import BinnacleStudentByProject from '../screens/BinnacleStudentByProject';
import FollowUpOfResearchProjectScreenAndRoute from '../screens/FollowUpOfResearchProjectScreen';
import StudentsByProjectScreen from '../screens/StudentsByProjectScreen';
import ProjectInfoScreen from '../screens/ProjectInfoScreen';

const ProjectsByCollaboratorRoute = () => {
  return (
    <Switch>
      <Route
        exact
        path={'/seguimiento-proyecto-investigacion'}
        component={FollowUpOfResearchProjectScreenAndRoute}
      />
      <Route
        exact
        path={'/seguimiento-proyecto-investigacion/:projectId'}
        component={ProjectInfoScreen}
      />
      <Route
        exact
        path={'/seguimiento-proyecto-investigacion/:projectId/estudiantes'}
        component={StudentsByProjectScreen}
      />
      <Route
        exact
        path={'/seguimiento-proyecto-investigacion/:projectId/estudiantes/:studentId/bitacora/'}
        component={BinnacleStudentByProject}
      />
    </Switch>
  );
};

export default ProjectsByCollaboratorRoute;
