import { Box, CardMedia, CircularProgress, Grid, Tooltip, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import Carousel from 'react-material-ui-carousel';
import { baseUrl } from '../../../../../api/mainApi';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { Directory } from '../../interfaces/dashboard.interfaces';
import {
  useGetDirectoriesQuery,
  useRegisterVisitStudentMutation,
} from '../../slices/libraryDashboardApiSlice';

const DatabasesList = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { userInfo, studentRecordReference } = useAppSelector((state) => state.auth);

  const [registerVisit] = useRegisterVisitStudentMutation();

  const handleVisit = () => {
    registerVisit({
      date: format(new Date(), 'yyyy-MM-dd'),
      educationLevelId: studentRecordReference?.[0]?.education_level_id
        ? Number(studentRecordReference?.[0]?.education_level_id)
        : 0,
      personId: Number(userInfo?.personId!),
      studentRecordId: userInfo?.studentInfo?.studentRecordId
        ? Number(userInfo?.studentInfo?.studentRecordId)
        : 0,
      type: 'open access',
    });
  };

  const { data: directories, isLoading } = useGetDirectoriesQuery({
    profileId: currentProfile?.profileId!,
  });

  const databasesGroups = directories?.reduce<Directory[][]>((acc, curr) => {
    if (acc.length > 0) {
      if (acc[acc.length - 1].length < 4) {
        acc[acc.length - 1].push(curr);
      } else {
        acc.push([curr]);
      }
    } else {
      acc.push([curr]);
    }
    return acc;
  }, []);

  return (
    <Grid px={2} py={4} container justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" align="center" color="primary">
          Directorio de bases de datos
        </Typography>
      </Grid>
      {isLoading && (
        <Grid item container xs={12} justifyContent="center" mt={2}>
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && directories && directories.length > 0 && (
        <Grid item xs={10}>
          <Carousel autoPlay>
            {databasesGroups?.map((databaseGroup) => (
              <Grid item container xs={12}>
                {databaseGroup?.map((database) => (
                  <Grid item xs={12} sm={6} md={3} p={2}>
                    <Tooltip title={database.name}>
                      <Box
                        sx={{
                          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                          display: 'flex',
                          width: '100%',
                          height: '100px',
                          padding: 2,
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '12px',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                        onClick={() => {
                          handleVisit();
                          window.open(database.library_url, '_blank');
                        }}
                      >
                        <CardMedia
                          classes={{ img: 'img' }}
                          component="img"
                          src={`${baseUrl}/show?name=${database.logo_url}`}
                          alt={database.name}
                          sx={{
                            // width: '250px',
                            height: '90%',
                            objectFit: 'contain',
                            margin: 'auto',
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Carousel>
        </Grid>
      )}
      {!isLoading && (!directories || directories?.length === 0) && (
        <Grid item xs={12} mt={2}>
          <Typography variant="h5" align="center">
            No hay bases de datos disponibles
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default DatabasesList;
