export const subPermissionKeys = {
  groupKey: 'meshs',

  read: 'meshs-listar-mallas',
  create: 'meshs-crear-mallas',
  edit: 'meshs-actualizar-mallas',
  delete: 'meshs-borrar-malla',
  getOne: 'meshs-obtener-malla',
  assign: 'mattermesh-crear-materias-mallas',
  updateAssign: 'mattermesh-actualizar-materias-mallas',
  removeAssign: 'mattermesh-borrar-materias-mallas', //

  getParentEducationLevel: 'education-levels-obtener-nivel-educativo',
  getModalities: 'catalogs-obtener-catalog',
  getCareerAndPrograms: 'education-levels-obtener-nivel-educativo',
  getSubjects: 'matters-listar-matters',
}; // satisfies PermissionsKeys;
