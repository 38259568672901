import { useHistory } from 'react-router-dom';
import { IconButton, Grid, useTheme, Typography } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import EditExternalHomologationForm from '../../components/external/EditExternalHomologationForm';

import MainCardForm from '../../../../shared/components/cards/MainCardForm';
import { useAppSelector } from '../../../../../hooks/useRedux';
import ConfirmExternalHomologation from '../../components/external/ConfirmExternalHomologation';
import { useTranslation } from 'react-i18next';

const EditHomologationExScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const { externalAppToConfirm } = useAppSelector((state) => state.homologations);

  // if (!Boolean(externalHomologationsList.length))
  //   return <Redirect to="/administrar-homologaciones" />;

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('academic.homologation.external.edit.title')}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <EditExternalHomologationForm />

        {externalAppToConfirm && <ConfirmExternalHomologation />}
      </Grid>
    </MainCardForm>
  );
};

export default EditHomologationExScreen;
