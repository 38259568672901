import {
  Table,
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { renderErrorOrEmptyRow } from '../../../../shared/helpers/render';
import { subjectsTable } from '../../interfaces/subjectsRegistered.interfaces';
import { IconX } from '@tabler/icons';
import { deleteListSubject } from '../../slices/requisitionsSlice';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { useTranslation } from 'react-i18next';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';

interface props {
  data: subjectsTable[];
  selectSubjectRegistered?: (id: number) => void;
}

const SubjectsTable = (props: props) => {
  const { t } = useTranslation();

  const { data, selectSubjectRegistered } = props;
  const dispatch = useAppDispatch();

  const totalHours = data.reduce((acc, cur) => acc + Number(cur.hours), 0);

  const renderRow = (p: subjectsTable, index: number) => (
    <TableRow className="table-row" key={data.indexOf(p)}>
      <TableCell align="center" width="5%">
        {data.indexOf(p) + 1}
      </TableCell>
      <TableCell width="10%">{p.subject?.name}</TableCell>
      <TableCell width="10%">{p.parallel}</TableCell>
      <TableCell width="10%">{p.hourhand}</TableCell>
      <TableCell width="5%">{p.campus?.name}</TableCell>
      <TableCell align="center" width="5%">
        {p.hours}
      </TableCell>
      <TableCell align="center" width="5%">
        {p.hours_total}
      </TableCell>
      <TableCell align="center" width="5%">
        <Tooltip title={t('academic.requisition.edit.wizard.step2.add_subject.exit')}>
          <IconButton>
            <IconX
              onClick={() => {
                dispatch(deleteListSubject(p));
              }}
              color="red"
            />
          </IconButton>
        </Tooltip>
        {selectSubjectRegistered && (
          <EditButton handleEdit={() => selectSubjectRegistered(index)} />
        )}
      </TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return (
      <>
        {data.map(renderRow)}
        {data.length > 0 && (
          <TableRow className="table-row">
            <TableCell colSpan={5} />
            <TableCell align="center" width="5%">
              {totalHours}
            </TableCell>
            <TableCell align="center" width="5%"></TableCell>
          </TableRow>
        )}
      </>
    );
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item sm={12} overflow={'hidden'}>
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">#</TableCell>
                    <TableCell>
                      {t('academic.requisition.edit.wizard.step2.add_subject.subject')}
                    </TableCell>
                    <TableCell>
                      {t('academic.requisition.edit.wizard.step2.add_subject.parallels')}
                    </TableCell>
                    <TableCell>
                      {t('academic.requisition.edit.wizard.step2.add_subject.hourhand')}
                    </TableCell>
                    <TableCell>
                      {t('academic.requisition.edit.wizard.step2.add_subject.campus')}
                    </TableCell>
                    <TableCell align="center">
                      {t('academic.requisition.edit.wizard.step2.add_subject.plan_hour')}
                    </TableCell>
                    <TableCell align="center">
                      {t('academic.requisition.edit.wizard.step2.add_subject.total_hours')}
                    </TableCell>
                    {selectSubjectRegistered && (
                      <TableCell align="center">
                        {t('shared.search_paginated_table.table.options')}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderRows()}
                  {data.length === 0 && renderErrorOrEmptyRow(11)}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </Grid>
      </Grid>
    </>
  );
};

export default SubjectsTable;
