import {
  Box,
  makeStyles,
  Typography,
  FormHelperText,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { UploadFileOutlined, InsertDriveFileOutlined } from '@material-ui/icons';
import { IconCircleX } from '@tabler/icons';
import { useEffect, useMemo, useState } from 'react';
import useFormatSizeFile from '../../hooks/useFormatSizeFile';
import { useAppSelector } from '../../../../hooks/useRedux';
import useFileFormatValidation from '../../../../hooks/useFileFormatValidation';
// import { useTranslati`on } from 'react-i18next';

const styles = makeStyles(() => ({
  fileUploadInput: {
    position: 'absolute',
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    outline: 'none',
    opacity: 0,
    cursor: 'pointer',
  },
  fileIcon: {
    mt: 3,
  },
  closeButton: {
    position: 'absolute',
    marginTop: -10,
    marginRight: -10,
    right: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface Props {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  file?: File;
  description?: string;
  typesAccepted?: string;
  marginTop?: number;
  showFileName?: boolean;
  onRemoveFile?: () => void;
  isLoading?: boolean;
  reset?: boolean;
  documentExist?: {
    name: string;
    url: string;
  } | null;
  url?: string;
  error?: string;
}

/**
 * @param handleChange  - Función que se ejecuta cuando se selecciona un archivo
 * @param label - Texto que se muestra en el botón
 * @param description - Texto que se muestra debajo del botón
 * @param typesAccepted - Tipos de archivos aceptados
 * @param marginTop - Margen superior
 * @param showFileName - Muestra el nombre del archivo seleccionado
 * @returns
 */
const DragArea = ({
  handleChange,
  label,
  description,
  typesAccepted = '*',
  marginTop,
  showFileName = true,
  onRemoveFile,
  isLoading,
  file: cFile = null!,
  reset = false,
  documentExist,
  url = '',
  error,
}: Props) => {
  const classes = styles();
  const { defaultConfiguration } = useAppSelector((state) => state.access);
  const [file, setFile] = useState<File>(null!);
  const [firstRender, setFirstRender] = useState(true);

  const changeFile = (e: any) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (!firstRender) {
      return;
    }

    setFile(cFile);
    setFirstRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cFile]);

  const [fileFormatError, setFileFormatError] = useState('');

  const { formatBytes } = useFormatSizeFile();

  const { loadedFile } = useAppSelector((state) => state.customization);

  const { validateFormatFile } = useFileFormatValidation();

  useEffect(() => {
    if (reset) {
      setFile(null!);
    }
  }, [reset]);
  // const isTypeFileAvailable = (file?: File): boolean => {
  //   if (
  //     typesAccepted === '*' ||
  //     typesAccepted.split(',').some((e) => e.replace('.', '') === file?.type.split('/')?.[1]) ||
  //     typesAccepted === file?.type
  //   ) {
  //     return true;
  //   } else if (
  //     typesAccepted.split('/')?.[1] === '*' &&
  //     typesAccepted.split('/')?.[0] === file?.type.split('/')?.[0]
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const marginBottom = useMemo(() => {
    if ((fileFormatError && file) || (fileFormatError && documentExist)) {
      return 16;
    }

    if ((fileFormatError && !file) || (fileFormatError && !documentExist)) {
      return 6;
    }

    if ((!fileFormatError && file) || (!fileFormatError && documentExist)) {
      return 12;
    }

    return 2;
  }, [fileFormatError, file, documentExist]);

  return (
    <Grid container>
      <Box
        sx={{
          width: '100%',
          mt: marginTop || 3,
          mb: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: '0.75rem',
            fontWeight: 'bold',
            textAlign: 'center',
            mb: 2,
          }}
        >
          {description || 'Depositar su archivo en esta sección'}:
        </Typography>
        <Box
          sx={{
            position: 'relative',
            height: '120px',
            border: '4px dashed #d0d7de',
            ml: '10px',
            mr: '10px',
            borderRadius: '12px',
            mb: marginBottom,
          }}
        >
          <input
            className={classes.fileUploadInput}
            type="file"
            accept={typesAccepted}
            onChange={(event) => {
              setFileFormatError('');
              if (!event.target.files?.[0]) return;

              const error = validateFormatFile(event.target.files?.[0], typesAccepted);

              if (error !== '') {
                setFileFormatError(error);
                return;
              }

              handleChange(event);
              changeFile(event);
            }}
          />
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 1,
            }}
          >
            <UploadFileOutlined />
            <Typography
              sx={{
                fontSize: '0.85rem',
                ml: '3px',
                fontWeight: 'bold',
              }}
            >
              {label || 'Subir Archivos'}
            </Typography>
          </Box>
          <Typography variant="subtitle2">Tipo de archivos permitidos: {typesAccepted}</Typography>
          <Typography variant="subtitle2">
            Tamaño máximo de archivo: {defaultConfiguration?.file_size} MB
          </Typography>
          {error && <FormHelperText error>{error}</FormHelperText>}

          {fileFormatError !== '' && (
            <FormHelperText sx={{ mb: 2 }} error>
              {fileFormatError}
            </FormHelperText>
          )}

          {file && showFileName && (
            <Box
              sx={{
                mt: 1,
                marginBottom: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                }}
              >
                {onRemoveFile && !isLoading && (
                  <IconCircleX
                    className={classes.closeButton}
                    onClick={() => {
                      setFile(null!);
                      onRemoveFile();
                    }}
                  />
                )}
                {isLoading ? (
                  // <CircularProgress
                  //   variant="determinate"
                  //   value={!!loadedFile ? (loadedFile * 100) / file.size : undefined}
                  // />
                  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress
                      variant="determinate"
                      value={!!loadedFile ? (loadedFile * 100) / file.size : undefined}
                    />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.floor((loadedFile * 100) / file.size)}%`}
                      </Typography>
                    </Box>
                  </Box>
                ) : url ? (
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="Este es un enlace externo"
                    href={url}
                  >
                    <InsertDriveFileOutlined
                      sx={{
                        fontSize: '48px',
                        mb: 1,
                      }}
                    />
                  </a>
                ) : (
                  <InsertDriveFileOutlined
                    sx={{
                      fontSize: '48px',
                      mb: 1,
                    }}
                  />
                )}
              </Box>

              <Typography
                sx={{
                  fontSize: '0.85rem',
                  ml: '3px',
                  fontWeight: 'bold',
                }}
              >
                {file.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: '0.7rem',
                  ml: '3px',
                  fontWeight: '5px',
                }}
              >
                {isLoading && formatBytes(loadedFile) + ' / '}
                {formatBytes(file.size)}
              </Typography>

              {/* {isTypeFileAvailable(file) ? (
              <>
                <Typography
                  sx={{
                    fontSize: '0.85rem',
                    ml: '3px',
                    fontWeight: 'bold',
                  }}
                >
                  {file.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.7rem',
                    ml: '3px',
                    fontWeight: '5px',
                  }}
                >
                  {isLoading && formatBytes(loadedFile) + ' / '}
                  {formatBytes(file.size)}
                </Typography>
              </>
            ) : (
              <FormHelperText sx={{ mb: 2 }} error>
                Tipo de archivo no permitido, no se subirá
              </FormHelperText>
            )} */}
            </Box>
          )}
          {documentExist && !file && (
            <Box
              sx={{
                mt: 1,
                marginBottom: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                }}
              >
                {onRemoveFile && !isLoading && (
                  <IconCircleX
                    className={classes.closeButton}
                    onClick={() => {
                      setFile(null!);
                      onRemoveFile();
                    }}
                  />
                )}
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Este es un enlace externo"
                  href={documentExist.url}
                >
                  <InsertDriveFileOutlined
                    sx={{
                      fontSize: '48px',
                      mb: 1,
                    }}
                  />
                </a>
              </Box>

              <Typography
                sx={{
                  fontSize: '0.85rem',
                  ml: '3px',
                  fontWeight: 'bold',
                }}
              >
                {documentExist.name}
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: '0.7rem',
                  ml: '3px',
                  fontWeight: '5px',
                }}
              >
                {isLoading && formatBytes(loadedFile) + ' / '}
                {formatBytes(file.size)}
              </Typography> */}

              {/* {isTypeFileAvailable(file) ? (
              <>
                <Typography
                  sx={{
                    fontSize: '0.85rem',
                    ml: '3px',
                    fontWeight: 'bold',
                  }}
                >
                  {file.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.7rem',
                    ml: '3px',
                    fontWeight: '5px',
                  }}
                >
                  {isLoading && formatBytes(loadedFile) + ' / '}
                  {formatBytes(file.size)}
                </Typography>
              </>
            ) : (
              <FormHelperText sx={{ mb: 2 }} error>
                Tipo de archivo no permitido, no se subirá
              </FormHelperText>
            )} */}
            </Box>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default DragArea;
